<template>
    <div class="auth__content hero-static content content-full">
        <!-- Avatar -->
        <div class="py-30 px-5 text-center content-header__wrapper">
            <img class="content-header__logo" src="~assets/img/wikiworks-logo.png" height="32" width="194"
                 alt="Wikiworks">
        </div>
        <!-- END Avatar -->

        <!-- Unlock Content -->
        <div class="row justify-content-center px-5">
            <div class="col-sm-8 col-md-6 col-lg-4 d-flex justify-content-center">
                <div class="modal__block autorization__content">
                    <h3 class="autorization__title">Истекло время подтверждения</h3>
                    <div class="autorization__tab-content">
                        <!-- Вкладка входа -->
                        <div class="autorization__tab">
                            <div class="autorization__send-message">
                                <div>
                                    Пожалуйста, отправьте повторный <router-link class="" to="/password_reset">запрос</router-link> для восстановления пароля
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Unlock Content -->
    </div>
</template>

<script>
export default {
    name: 'FailConfirm'
}
</script>

<style lang="scss" scoped>
@import "auth";
</style>
